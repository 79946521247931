@import "modules/variables.scss";
@import "modules/mixins.scss";

#security-link {
    cursor: pointer;
    position: absolute;
    top: 200px;
    z-index: 100;
    right: 0px;
    background-color: #152434;
    color: white;
    border-radius: 8px 0 0 8px;
    font-weight: 700;
    width: 200px;
    height: 80px;
    padding: 0 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    i {
        font-size: 22px;
    }

    div {
        width: 110px;
    }
}