@import "modules/variables.scss";
@import "modules/mixins.scss";

#greenly-popup {
    background-color: #fff;
    position: relative;
    margin: 120px auto 0 auto;
    width: 536px;
    line-height: 17px;
    text-align: left;
    font-family: 'proxima-nova';
    padding-bottom: 5px;

    #greenly-popup-close {
        background-color: #038096;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
        width: 50px;
        height: 50px;
        position: absolute;
        left: 510px;
        top: -24px;
        cursor: pointer;
        float: left;

        .close-icon {
            color: white;
            font-size: 36px;
            position: relative;
            transform: translate(-50%, -50%);
            top: 50%;
            left: 50%;
        }
    }

    .greenly-popup-header {
        color: #038096;
        font-size: 22px;
        text-align: center;
        line-height: 30px;
    }

    .greenly-popup-content {
        font-size: 14px;
        margin: 15px 50px;
    }

    .greenly-popup-link {
        background-color: #38EF7D;
        font-size: 16px;
        text-align: center;
        margin: auto;
        width: 243px;
        line-height: 40px;
        font-weight: 700;
        border-radius: 25px;
        box-shadow: 0 3px 10px #888888;
        color: black;
        display: block;
    }

    #greenly-popup-footer-image {
        margin-top: 15px;
    }

    .underline {
        text-decoration: underline;
    }

    .greenly-popup-footer {
        margin: 5px 70px 0 70px;
        font-size: 12px;
        text-align: center;

        .greenly-popup-warning {
            color: #038096;
        }
        
        .greenly-popup-footer-content {
            font-style: italic;
        }
    }
}