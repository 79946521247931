@import "modules/variables.scss";
@import "modules/mixins.scss";

.day-header {
    position: relative;
    height: 20px;

    .remote-working-indicator {
        position: absolute;
        left: 66px;
        color: $menuColor;
        display: inline-block;
    }

    .remote-working-hider {
        background-color: white;
        display: inline-block;
        position: absolute;
        opacity: 0.8;

        &.am-hider {
            width: 11px;
            left: -2px;
        }

        &.pm-hider {
            width: 11px;
            left: 9px;
        }
    }
}

.remote-day-dividers {
    width: 167px;
    height: 120px;
    position: relative;
    top: -30px;
    left: -10px;

    .remote-day-divider {
        background-color: $menuColor;
        width: 100%;
        opacity: 0.5;
        height: 100%;
    }

    .remote-day-half-divider {
        width: calc(167px / 2);
        height: 100%;
        opacity: 0.5;
        display: inline-block;

        &:first-of-type {
            border-right: 1px solid rgba(0, 0, 0, 0.2);
        }

        &.selected {
            background-color: rgba(35, 185, 214, 0.4) !important;
        }

        &.not-selectable {
            background-color: rgba(165, 17, 96, 0.4) !important;
            cursor: not-allowed !important;
        }
    }
}


.day-remote-editing {
    border: 1px solid $menuColor;
}

.see-more-overtime {
    position: relative;
    left: 130px;
    font-size: 12px;
    top: 30px;
    width: 20px;
    text-align: center;
    border-radius: 50%;
    background-color: $blueSynchrone;
    cursor: pointer !important;
    color: white;
    height: 19px;
    padding-top: 3px;

    i {
        cursor: pointer !important;
    }

    .more-overtime-icon {
        font-weight: 400;
    }

    &:hover {
        transform: scale(1.1);
    }
}

.higher-see-more-overtime {
    top: 10px !important;
}

.hours-for-day {
    font-style: italic;
    font-weight: 300;
    font-size: 12px;
    position: relative;
    left: 120px;
    top: -19px;
    color: #979797;
    height: 15px;
}

.add-extra-activity {
    position: relative;
    left: 169px;
    top: 20px;
    cursor: pointer;
    width: 15px;

    i {
        font-size: 24px;
        color: $blueSynchrone;
    }
}
