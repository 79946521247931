@import "modules/variables.scss";
@import "modules/mixins.scss";

.parameters-title {
    font-size: 30px;
    margin: 20px 0;
}

.parameters-category-container {
    background-color: #fff;
    border-radius: 10px 10px 0 0;

    .parameters-category-title {
        border-radius: 10px 10px 0 0;
        color: #fff;
        background-color: $green;
        font-size: 24px;
        height: 50px;
        line-height: 50px;
        font-weight: 700;
        padding: 0 20px;
    }

    .parameters-subcategory-container {
        padding: 35px;

        .parameters-subcategory-title {
            font-weight: 600;
        }

        .parameters-subcategory-content {
            padding-top: 15px;
        }
    }

    .parameters-checkbox {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;

        &:checked + .parameters-checkmark:after {
            display: block;
        }
    }

    .parameters-checkbox-label {
        text-transform: capitalize;
    }

    .parameters-checkmark {
        height: 20px;
        width: 20px;
        display: inline-block;
        position: relative;
        top: 5px;
        border: 1px solid black;
        padding: 3px;
        margin-right: 10px;

        &:after {
            content: "";
            position: relative;
            display: none;
            width: 12px;
            height: 12px;
            background-color: $menuColor;
        }
    }
}

.parameters-buttons-container {
    margin-top: 50px;
    display: flex;
    justify-content: space-evenly;

    .parameters-buttons-confirm {
        color: $white;
        background-color: $notifierSuccessColor;
        width: 240px;
        height: 40px;
        line-height: 40px;
        border-radius: 50px;
        cursor: pointer;
        border: none;
        text-align: center;
    }
}
