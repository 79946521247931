@import "modules/variables.scss";
@import "modules/mixins.scss";

.download-cra {
    cursor: pointer;
    font-family: "Open Sans";
    font-size: 13px;
    font-weight: 600;
    display: inline-block;
    margin-left: 20px;
}

.modality-container {
    font-size: 14px;
    font-weight: 400;
    background-color: #fff;
    height: 20px;
    line-height: 20px;
    text-align: right;
    padding: 0 10px;

    .modality-label {
        font-weight: 700;
    }
}

.cancel-remote-work-btn {
    color: $white;
    width: 420px;
    height: 37px;
    text-align: center;
    font-family: "Open Sans";
    font-size: 14px;
    line-height: 37px;
    letter-spacing: 0.4px;
    border-radius: 50px;
    margin-top: 23px;
    margin-right: 15px;
    display: inline-block;
    cursor: pointer;
    background-color: $inactiveSendMonth;
}